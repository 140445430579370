<template>
  <v-dialog v-model="shortcutDialog" max-width="400px" persistent>
    <v-card flat>
      <v-card-title> OrderBox Keyboard Shortcuts </v-card-title>
        <v-card-text class="body-2">
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-center"> Shortcut Keys </th>
                <th class="text-center"> Description </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> Alt + O </v-chip> </td>
                <td style="font-size: 13px;"> Opens the Order Box </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> Alt + X </v-chip> </td>
                <td style="font-size: 13px;"> Closes the Order Box </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> B </v-chip> </td>
                <td style="font-size: 13px;"> For BUY </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> S </v-chip> </td>
                <td style="font-size: 13px;"> For SELL </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> N </v-chip> </td>
                <td style="font-size: 13px;"> For NRML </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> I </v-chip> </td>
                <td style="font-size: 13px;"> For INTRADAY </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> <v-icon small> fas fa-arrow-up </v-icon> </v-chip> </td>
                <td style="font-size: 13px;"> For increasing lot quantity </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> <v-icon small> fas fa-arrow-down </v-icon> </v-chip> </td>
                <td style="font-size: 13px;"> For decreasing lot quantity </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> Alt + P </v-chip> </td>
                <td style="font-size: 13px;"> For placing the Order </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> Alt + K </v-chip> </td>
                <td style="font-size: 13px;"> Opens the Shortcut Page </td>
              </tr>
              <tr>
                <td class="text-center"> <v-chip small color="#CFD8DC" text-color="#37474F" class="font-weight-bold"> ESC </v-chip> </td>
                <td style="font-size: 13px;"> Closes the Shortcut Page </td>
              </tr>
              <tr>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click.native="shortcutDialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>

    export default {
      metaInfo: {
              // title: 'Opstra Options Analytics- OrderBox',
              // override the parent template and just use the above title only
              titleTemplate: null,
              meta: [
                { charset: 'utf-8' },
                { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { name: 'description', content: 'shortcut' }
              ],
          },
    
      data() {
        return {
          shortcutDialog: false,
        }
      },
      
      mounted() {
        window.addEventListener("keydown", this.handleKeyDown);
        window.addEventListener("keydown", this.handleEscKey);
      },
      
      beforeDestroy() {
        window.removeEventListener("keydown", this.handleKeyDown);
        window.removeEventListener("keydown", this.handleEscKey);
      },
    
      methods: {
        handleKeyDown(event) {
          if (event.key.toUpperCase() === "K" && event.altKey) {
            this.shortcutDialog = true;
          }
        },
        handleEscKey(event) {
          if (event.key === "Escape") {
            this.shortcutDialog = false;
          }
        }
      }
    }
    
  </script>
  