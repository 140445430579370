<template>
  <v-app id="app">
    <v-navigation-drawer v-model="drawer" dark  app fixed  temporary>
      <v-divider></v-divider>
      <v-list nav dense class="hidden-sm-and-up">
        <v-list-item-group v-if="!isLoggedIn">
          <v-list-item
            v-for="item in menuDefaultItems"
            :key="item.index"
            link
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
          @click="ssologin"
          >
            <v-list-item-icon>
              <v-icon >fas fa-unlock-alt </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Login/Signup</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group v-if="isLoggedIn">
          <v-list-item
            v-for="item in menuItems"
            :key="item.index"
            link
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-group
          sub-group
          v-if="isLoggedIn"
        >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </template>
            <!--subsubitem category list-->
            <v-list-item v-for="item in menuReportsItems" 
              :to="item.link"
              :key="item.index">
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
        </v-list-group>
      <v-list-group
          sub-group
          v-if="isLoggedIn"
        >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Futures</v-list-item-title>
          </v-list-item-content>
        </template>
            <!--subsubitem category list-->
            <v-list-item v-for="item in menuFuturesItems" 
              :to="item.link"
              :key="item.index">
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
        </v-list-group>

        <v-list-group
          sub-group
          v-if="isLoggedIn"
        >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Options</v-list-item-title>
          </v-list-item-content>
        </template>
            <!--subsubitem category list-->
            <v-list-item v-for="item in menuOptionsItems" 
              :to="item.link"
              :key="item.index">
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
        </v-list-group>

        <v-list-group
          sub-group
          v-if="isLoggedIn"
        >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Open Interest</v-list-item-title>
          </v-list-item-content>
        </template>
            <!--subsubitem category list-->
            <v-list-item v-for="item in menuOpenInterestItems" 
              :to="item.link"
              :key="item.index">
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
        </v-list-group>

        <v-list-group
          sub-group
          v-if="isLoggedIn"
        >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>FIIDII</v-list-item-title>
          </v-list-item-content>
        </template>
            <!--subsubitem category list-->
            <v-list-item v-for="item in menuFIIDIIItems" 
              :to="item.link"
              :key="item.index">
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
        </v-list-group>

        <v-list-group
          sub-group
          v-if="isLoggedIn"
        >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Apps</v-list-item-title>
          </v-list-item-content>
        </template>
            <!--subsubitem category list-->
            <v-list-item v-for="item in apps" 
              :to="item.link"
              :key="item.index">
              <v-list-item-icon>
                <v-icon>{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item>
        </v-list-group>

        <v-list-item-group v-if="isLoggedIn">
          <v-list-item
            v-for="item in menuAccountItems"
            :key="item.index"
            link
            :to="item.link"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="onLogout" v-if="isLoggedIn">
                <v-icon left >fas fa-sign-out-alt</v-icon>
                &nbsp;&nbsp;
                <v-list-item-title >Logout</v-list-item-title>
            </v-list-item>
        </v-list-item-group>

        

      </v-list>
    </v-navigation-drawer>
    
    <v-app-bar app fixed flat dark dense class="primary" >
      
      <span >
      <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
      </span>
      <v-toolbar-title @click="$router.push('/').catch(err => {})" tag="span" style="cursor:pointer" class="hidden-sm-and-down">Opstra Options Analytics</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only ml-4 px-2">
              <v-btn text class="primary" to="/strategybuilder" v-if="isLoggedIn">
                  Builder
              </v-btn>
              <v-btn text class="primary" to="/portfolio" v-if="isLoggedIn">
                  Portfolio
              </v-btn>
              <v-btn text class="primary" to="/watchlist" v-if="isLoggedIn">
                  Watchlist
              </v-btn>
              <v-btn text class="primary" to="/positions" v-if="isLoggedIn">
                  Positions
              </v-btn>
              <v-menu open-on-hover offset-y transition="slide-x-transition" bottom v-if="isLoggedIn">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" class="primary">
                        Reports
                        <v-icon right>fas fa-caret-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                  
                    <v-list-item v-for="(item, index) in menuReportsItems" :key="index"  :to="item.link">
                            <v-icon left >{{item.icon}}</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
              <v-menu open-on-hover offset-y transition="slide-x-transition" bottom v-if="isLoggedIn">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" class="primary">
                        Futures
                        <v-icon right>fas fa-caret-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                  
                    <v-list-item v-for="(item, index) in menuFuturesItems" :key="index"  :to="item.link">
                            <v-icon left >{{item.icon}}</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu open-on-hover offset-y transition="slide-x-transition" bottom right v-if="isLoggedIn">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" class="primary">
                        Options
                        <v-icon right>fas fa-caret-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense >
                    <v-list-item v-for="(item, index) in menuOptionsItems" :key="index" :to='item.link'>
                            <v-icon left >{{item.icon}}</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu open-on-hover offset-y transition="slide-x-transition" bottom right v-if="isLoggedIn">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" class="primary">
                        Open Interest
                        <v-icon right>fas fa-caret-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense >
                    <v-list-item v-for="(item, index) in menuOpenInterestItems" :key="index" :to="item.link">
                            <v-icon left >{{item.icon}}</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu open-on-hover offset-y :close-on-content-click="false" v-if="isLoggedIn">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" class="primary">
                        FII/DII
                        <v-icon right>fas fa-caret-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense >
                    <v-list-item v-for="(item, index) in menuFIIDIIItems" :key="index" :to="item.link">
                            <v-icon left >{{item.icon}}</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu open-on-hover offset-y :close-on-content-click="false" v-if="isLoggedIn">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" class="primary">
                        Apps
                        <v-icon right>fas fa-caret-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense >
                    <v-list-item href="https://zone.definedgesecurities.com/" target="_blank">
                            <icon-base width="32" height="32"><zone-icon/></icon-base>
                            &nbsp;&nbsp;
                            <v-list-item-title>Zone - Trading Terminal</v-list-item-title>
                    </v-list-item>
                    <v-list-item href="https://www.definedgesecurities.com/products/buzzar/" target="_blank">
                            <icon-base  width="32" height="32"><buzzar-icon/></icon-base>
                            &nbsp;&nbsp;
                            <v-list-item-title>Buzzar - Unique Alert System</v-list-item-title>
                    </v-list-item>
                    <v-list-item href="https://www.definedgesecurities.com/products/rzone/" target="_blank">
                            <icon-base  width="32" height="32"><rzone-icon/></icon-base>
                            &nbsp;&nbsp;
                            <v-list-item-title>RZone - Scanners & Backtesting</v-list-item-title>
                    </v-list-item>
                    <v-list-item href="https://radar.definedgesecurities.com/" target="_blank">
                            <icon-base  width="32" height="32"><radar-icon/></icon-base>
                            &nbsp;&nbsp;
                            <v-list-item-title>Radar - Fundamnetal Analysis & Scanning</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

      </v-toolbar-items>
      <v-spacer></v-spacer>
      <!-- <v-toolbar-items class="hidden-sm-and-down">
        <v-btn text class="primary" to="/account">
                <v-icon left >fas fa-user</v-icon>
                {{ credentials.uid }}
        </v-btn>
      </v-toolbar-items> -->
      <v-menu open-on-hover offset-y transition="slide-x-transition" bottom right v-if="isLoggedIn">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn text v-bind="attrs" v-on="on" class="primary">
                      <v-avatar
                        color="grey"
                        size="36"
                        large
                      >
                        <span class="white--text">{{getInitials}}</span>
                      </v-avatar>
                        <!-- <span class="ml-2">{{credentials.uid}}</span> -->
                        <v-icon right>fas fa-caret-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                  <v-list-item @click="openOrderBox">
                            <v-icon left >fas fa-keyboard</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title >OrderBox</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/account">
                            <v-icon left >fas fa-user-circle</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title>Account</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item to="/margin">
                            <v-icon left >fas fa-user-circle</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title>Margin</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item @click="onLogout">
                            <v-icon left >fas fa-sign-out-alt</v-icon>
                            &nbsp;&nbsp;
                            <v-list-item-title >Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
      <v-toolbar-items  px-5 class="hidden-xs-only" v-if="!isLoggedIn">
          <v-btn text class="primary" 
          to="/">
          
          <v-icon left>fas fa-home</v-icon>Home
          </v-btn>
      </v-toolbar-items>

      <v-toolbar-items px-5 class="hidden-xs-only justify-end" v-if="!isLoggedIn">
          <v-btn text class="primary" 
          @click="ssologin">          
          <v-icon left>fas fa-unlock-alt</v-icon>Login/Sign up
          </v-btn>
      </v-toolbar-items>

      

      <v-toolbar-items  px-2 class="ml-2">
        <v-btn text class="primary" @click="changeTheme"><v-icon >fas fa-moon</v-icon></v-btn>
        
      </v-toolbar-items>
    </v-app-bar>
    <v-dialog
      v-model="sessionTimeout"
      width="500"
      @input="setSessionTimeoutFalse"
    >
      <v-card class="text-xs-center">
        <v-card-title
          class="headline grey lighten-2 "
          primary-title
        >
          Session Timeout
        </v-card-title>

        <v-card-text >
          <div class="font-weight-bold pa-3 mt-2 justify-center" >Your Session Timedout. Please Login Again.</div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn class="primary" @click="ssologin">Login</v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="orderExecutionStatus"
      class="font-weight-bold"
      bottom
      rounded
      multi-line
      outlined
      right
      timeout="3000"
      @input="setOrderExecutionStatusFalse"
    >
  
      <v-spacer></v-spacer>
      <template v-slot:action="{ attrs }">
    
          <v-btn
            color="grey"
            icon
            v-bind="attrs"
            @click="setOrderExecutionStatusFalse"
          >
            <v-icon>fas fa-close</v-icon>
          </v-btn>
        </template>
    
      <br/>
      <span class="font-weight-bold title-2" :class="orderStatusText.color">
        {{ orderStatusText.status }}
      </span>
      <v-divider></v-divider><br/>
      <span class="font-weight-bold">
        {{ orderStatusText.message }}
        {{ orderStatusText.transactiontype + ' '+orderStatusText.symbol+' Order '+orderStatusText.statustext }} 
      </span><br/><br/>
        <span v-if="orderStatusText.ordernumber" class="grey--text darken-3">
          Order #:{{orderStatusText.ordernumber}}
        </span>
        <span v-if="orderStatusText.reason">
          <br/>'Reason: '+{{orderStatusText.reason}}
        </span>

        
    </v-snackbar>
    <v-main>
      <v-container fluid >
        <v-row  justify="end">
          <v-col md="2" sm="3" cols="6" v-for="(item,index) in indextickers" :key="index">
            <v-card outlined class="caption">
              <p class="font-weight-bold text-center ma-0" >{{item.ticker}}</p>
              <p class="green--text font-weight-bold text-center ma-0" v-if="(item.priceChange)>=0">{{ item.lastPrice }} ({{ item.priceChange }}%)</p>
              <p class="red--text font-weight-bold text-center ma-0" v-if="(item.priceChange)<0">{{ item.lastPrice }} ({{ item.priceChange }}%)</p>
            </v-card>
          </v-col>
        </v-row>

        <v-row xs12>
              <v-alert
                v-model="alert"
                dismissible
                type="success"
              >
              <span class="body-2">{{msg}}</span>
              </v-alert>
        </v-row>
        <router-view> </router-view>


      </v-container>
    </v-main>
    <OrderBox />
    <Shortcut />
  </v-app>
</template>

<script>
import axios from "axios" 
// import RobustWebSocket from "robust-websocket"
// import SharedWebSocket from "shared-websockets";
import SharedWebSocket from './sharedwebsocket';
import IconBase from './components/icon-base.vue'
import ZoneIcon from './components/icons/zone-icon.vue'
import BuzzarIcon from './components/icons/buzzar-icon.vue'
import RzoneIcon from './components/icons/rzone-icon.vue'
import RadarIcon from './components/icons/radar-icon.vue'
import OrderBox from './components/trading/OrderBox.vue';
import Shortcut from './components/trading/Shortcut.vue';

export default {
  name: "App",
  components:{
    IconBase,
    ZoneIcon,
    BuzzarIcon,
    RzoneIcon,
    RadarIcon,
    OrderBox,
    Shortcut
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown);
  },
  
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  data: () => ({
    baseURL: process.env.VUE_APP_ROOT_API,
    msg:'',
    alert:false,
    drawer: false,
    toolbar: true,
    creds:'',
    menuDefaultItems:[
      { icon: "fas fa-home", title: "Home", link: "/" },
   //   { icon: "fas fa-unlock-alt", title: "Login/Sign up", link: "ssologin" },
    ],
    menuItems: [
      { icon: "fas fa-home", title: "Home", link: "/" },
      // { icon: "fas fa-table ", title: "Credentials", link: "credentials" },
      {
        icon: "fas fa-table ",
        title: "Strategy Builder",
        link: "strategybuilder",
      },
      { icon: "fas fa-table ", title: "Portfolio", link: "portfolio" },
      { icon: "fas fa-table ", title: "Orders", link: "orders" },      
      { icon: "fas fa-table ", title: "Positions", link: "positions" },
      { icon: "fas fa-table ", title: "Margin", link: "margin" },
      // { icon: "fas fa-table ", title: "Open Interest", link: "openinterest" },
      // { icon: "fas fa-table ", title: "Options Backtesting", link: "backtesting" },
      // { icon: "fas fa-table ", title: "Options Dashboard", link: "dashboard" },
      // { icon: "fas fa-table ", title: "Options Activity", link: "optionsactivity" },
      // { icon: "fas fa-table ", title: "Heatmap", link: "heatmap" },
      // { icon: "fas fa-table ", title: "Options Algorithm", link: "optionsalgo" },
      // { icon: "fas fa-table ", title: "Results Calendar", link: "results-calendar" },
      // { icon: "fas fa-table ", title: "Historical Results Timing", link: "historical-results-timing" },
    ],
    menuReportsItems:[
        {icon:'fas fa-list',title:'Orders',link:'/orders'},
        {icon:'fas fa-newspaper',title:'Margin',link:'/margin'},
        ],

     menuFuturesItems:[
        {icon:'fas fa-th-large',title:'Futures Dashboard',link:'/futures/dashboard'},
        {icon:'fas fa-chart-bar',title:'Futures Open Interest',link:'/futures'},
        {icon:'fas fa-chart-line',title:'Futures Intra-Day',link:'/futures/intra'},
        {icon:'fas fa-table',title:'Futures Buildup',link:'/futures/buildup'},
        {icon:'fas fa-th',title:'Heatmap',link:'/heatmap'},
        {icon:'fas fa-chart-bar',title:'Moving Averages',link:'/futures/movingaverages'},
        {icon:'fas fa-ban',title:'MWPL',link:'/mwpl'},
        ],

      menuOptionsItems:[
        {icon:'fas fa-vial',title:'Options Backtesting',link:'/backtesting'},
        {icon:'fas fa-vial',title:'Options Simulator',link:'/options-simulator'},
        {icon:'fas fa-hdd',title:'Options Algorithm',link:'/optionsalgo'},
        {icon:'fas fa-th-large',title:'Options Dashboard',link:'/options'},
        {icon:'fas fa-chart-line',title:'IV Charts',link:'/ivchart'},
        {icon:'fas fa-cube',title:'Volatility Surface',link:'/volsurface'},
        {icon:'fas fa-chart-line',title:'Strategy Charts',link:'/strategy-charts'},
        {icon:'fas fa-chart-area',title:'Historical Charts',link:'/historical-charts'},
        {icon:'fas fa-ankh',title:'Strategy Builder',link:'/strategybuilder'},
        {icon:'fas fa-chart-line',title:'Volatility Skew',link:'/volskew'},
        {icon:'fas fa-calendar-alt',title:'Results Calendar',link:'/results-calendar'},
        {icon:'fas fa-clock',title:'Historical Results',link:'/historical-results-timings'}],

      menuOpenInterestItems:[
        {icon:'fas fa-chart-bar',title:'Open Interest',link:'/openinterest'},
        {icon:'fas fa-tenge',title:'Total Open Interest',link:'/toi'},
        {icon:'fas fa-tenge',title:'Historical TOI',link:'/htoi'},
        {icon:'fas fa-rainbow',title:'MultiTOI',link:'/multitoi'},
        {icon:'fab fa-product-hunt',title:'Put Call OI Dynamics',link:'/putcalloi'},
        {icon:'fas fa-table',title:'Options Buildup',link:'/optionsbuildup'},
        {icon:'fas fa-chart-line',title:'Open Interest Charts',link:'/oicharts'},
        {icon:'fas fa-chart-area',title:'PCR',link:'/pcr'},
        {icon:'fas fa-chart-line',title:'Straddles',link:'/straddles'},
        {icon:'fas fa-dice-six',title:'Option Chain',link:'/optionchain'},
        {icon:'fas fa-compass',title:'Unusual Options Activity',link:'/uoa'},
        {icon:'fas fa-rss',title:'Trividh',link:'/trividh'},
        {icon:'fas fa-random',title:'Open Interest Crossover',link:'/oicrossover'},
        {icon:'fas fa-bolt',title:'Options Activity',link:'/optionsactivity'},
        
        ],
        menuAccountItems:[
        {icon:'far fa-user-circle',title:'Account',link:'/account'},
        // {icon:'far fa-user-circle',title:'Margin',link:'/margin'},
        // {icon:'fas fa-money-bill-wave',title:'Pricing',link:'/pricing'},
        ],
        menuFIIDIIItems:[
        {icon:'fas fa-coins',title:'FII/DII Cash Market',link:'/fiidii'},
        {icon:'fas fa-coins',title:'FII/DII Derivatives',link:'/fiidii/derivatives'},
        ],
        apps:[
        ],
        indextickers:[],
        niftytprice:'',
        bankniftyprice:'',
        niftytpricechange:'',
        bankniftypricechange:'',
    
  }),

  created(){
    this.getSpotData()
  },

  beforeMount () {
        return axios.get('/api/msg')
        .then((response)=>{
            //console.log(response.data)
            this.msg = response.data.msg
            if (response.data.status==='true'){
              this.alert = true
            }else{
              this.alert = false
            }
                    }
        )
        .catch(error=>{
          // if (error.response.status==401){
          //           this.$store.dispatch('setSessionTimeout')
          //               }
          console.log(error)
        })
    },


  methods: {

    async getSpotData(){
      // var bankniftyspotticker = 'Nifty Bank'
      // var niftyspotticker = 'Nifty 50'
      var niftytoken='26000'
      var bankniftytoken='26009'

      // return await axios.get(
      // `/opstraapi/opstratoken/${bankniftyspotticker}&index&30JUN2022&XX&XX`)
      // .then((bnfresponse) =>{
      // bankniftytoken = bnfresponse.data.token;
      // console.log(niftytoken,bankniftytoken)
          // console.log(this.credentials)
      var susertoken = this.credentials.susertoken;
      var uid= this.credentials.uid
      var actid= this.credentials.actid
      var wsurl = this.credentials.wsurl
      // var niftyspotticker = 'Nifty 50'
      // console.log(wsurl)
      this.indextickers = [{'ticker':'NIFTY','lastPrice':'','priceChange':'','token':niftytoken},{'ticker':'BANKNIFTY','lastPrice':'','priceChange':'','token':bankniftytoken}]
      var alltickers =["NSE|"+niftytoken,"NSE|"+bankniftytoken].join('#')
      let self = this;

            var rws = new SharedWebSocket(wsurl,null,null); 
            console.log("Connecting");

            rws.onopen = function (e) {
              console.log(e)
              if (e){
                var json = {
                t: "c",
                uid: uid,
                actid: actid,
                source: "OPST",
                susertoken: susertoken,
                };
              console.log(json);
              rws.send(JSON.stringify(json));
              console.log("Sending message");

              }else{
                console.log('already connected')
                var tickerjson = { t: "t", k: alltickers };
                console.log(tickerjson);
                rws.send(JSON.stringify(tickerjson));
              }
            }

            setInterval(
              function () {
              var heartbeatjson = { t: "h"};
              rws.send(JSON.stringify(heartbeatjson))
              }.bind(this),
              1000 * 1 * 50
            );

            var i = 0;
            rws.onmessage = function (event) {
              if (i == 0) {
                var tickerjson = { t: "t", k: alltickers };
                console.log(tickerjson);
                rws.send(JSON.stringify(tickerjson));
                i++
            }else{
              var exampledata = JSON.parse(event.data);
                // console.log(exampledata)
                  for (var k = 0; k < self.indextickers.length; k++) {
                    if ((exampledata["tk"] == self.indextickers[k].token.toString())&&exampledata['lp']) {
                      // console.log(exampledata['lp'])
                      self.indextickers[k].lastPrice =exampledata['lp']
                      self.indextickers[k].priceChange =exampledata['pc']
                    }
                  }
             }
            }

            rws.onclose = function(e) {
              console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
              setTimeout(function() {
                this.getSpotData();
              }, 1000);
            }
                
                         
    // })


    },

    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    setOrderExecutionStatusFalse() {
      this.$store.dispatch('setOrderExecutionStatus',false)
    },

    setSessionTimeoutFalse() {
      this.$store.dispatch('setSessionTimeout',false)
    },
    ssologin(){
      window.location.href=this.baseURL+'ssologin'
    },
    onLogout(){
      window.location.href = this.baseURL+'logout';
    },
    handleKeyDown(event) {
      if (event.key === 'O' && event.altKey) {
        event.preventDefault();
        this.$store.dispatch('toggleOrderBox');
      }
    },
    openOrderBox() {
      // Simulate Alt+K keyboard event
      const event = new KeyboardEvent("keydown", { key: "O", altKey: true });
      window.dispatchEvent(event);
    }
  },

  computed:{
    orderExecutionStatus(){
        return this.$store.state.orderExecutionStatus
      },
    
    orderStatusText(){
      return this.$store.state.orderStatusText
    },
    sessionTimeout(){
        return this.$store.state.sessiontimeout
      },

    isLoggedIn(){
      return this.$store.getters.susertoken !== null && this.$store.getters.susertoken!== undefined
    },

    credentials(){
      return this.$store.getters.credentials
    },

    getInitials(){
            return this.$store.getters.username.firstname[0]+this.$store.getters.username.lastname[0]
            
        },

  },

  

  watch: {
    credentials(newValue) {
      console.log(newValue)
      this.getSpotData()
      }
    }
  
};
</script>
