import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Meta from 'vue-meta'
import VueNumberInput from "@chenfengyuan/vue-number-input";
import HighchartsVue from "highcharts-vue";
import EditPositions from "@/components/strategybuilder/EditPositions.vue";
//import Vuetify, { VTooltip, VToolbar, VCard, VIcon, VBtn } from "vuetify/lib";
//import colors from "vuetify/lib/util/colors";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from 'axios'
import AmCharts from "amcharts3";
import AmSerial from "amcharts3/amcharts/serial";
import AmStockChart from "amstock3/amcharts/amstock";
import dayjs from "dayjs";

Vue.use(HighchartsVue);
Vue.config.productionTip = false;
Vue.component(VueNumberInput.name, VueNumberInput);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(Meta)
Vue.use(AmCharts);
Vue.use(AmSerial);
Vue.use(AmStockChart);
Vue.filter("currency", (value) => {
    if (value > 0.0) {
        return "₹ +" + value.toLocaleString();
    } else {
        return "₹ " + value.toLocaleString();
    }
});
Vue.filter("currencynumber", (value) => {
    if (value > 0.0) {
        return value.toLocaleString();
    } else {
        return value.toLocaleString();
    }
});

Vue.filter("percent", (value) => {
    if (value > 0.0) {
        return "+" + value;
    } else {
        return value;
    }
});

Vue.filter("round", (value) => {
    // console.log(value)
    if (!value) return 0;
    return Number(value).toFixed(2).toLocaleString();
});

Vue.filter("roundint", (value) => {
    if (!value) return 0;
    return Number(value).toFixed().toLocaleString();
});

Vue.filter("percentGreek", (value) => {
    return (value / 100).toFixed(4);
});

Vue.filter("thousand", (value) => {
    if (!isNaN(value) && value > 0) {
        return "+" + new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
        }).format(value);
    } else if (!isNaN(value) && value < 0) {
        return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
        }).format(value);
    } else {
        return value;
    }
});

Vue.filter("separator", (value) => {
    if (!isNaN(value)) {
        return new Intl.NumberFormat("en-US").format(value);
    } else if (isNaN(value)) {
        return "-"
    } else {
        return value
    }
});

Vue.filter("datestringFormat", (value) => {
    if (!value) return null;
    return dayjs(value).format('DD MMMM YYYY');
});

Vue.filter("dateTimeFormat", (value) => {

    if (!value) return null;

    return dayjs(new Date(value * 1000)).format('DD MMM YYYY HH:mm');

});

Vue.filter("dateTime", (value) => {
    if (value == '-') return '-';

    return dayjs(new Date(value)).format('DD MMM HH:mm A');

});

new Vue({
    vuetify,
    router,
    store,
    EditPositions,
    render: h => h(App),
    async created() {
        //console.log(window.location.pathname)
        const baseUrl = process.env.VUE_APP_ROOT_API

        //debugger // eslint-disable-line no-debugger
        return await axios.get(baseUrl + 'api/session/')
            .then((response) => {
                //debugger // eslint-disable-line no-debugger
                console.log('MAIN PAGE')
                console.log(response)
                if (response.data.sub) { //change here
                    this.$store.dispatch('autoLogin', response.data.sub) //change here
                    this.$store.dispatch('Name', { 'firstname': response.data.firstName, 'lastname': response.data.lastName, 'email': response.data.email, 'role': response.data.userrole })
                    this.$store.dispatch('Credentials', response.data.norenSessionData)
                    this.$store.dispatch('Papertrading', response.data.paperIdentity)

                    if (response.data.usergroups !== null || response.data.usergroups !== "") {
                        let krapending = response.data.usergroups.split(",").filter(item => item.includes('KRA_PENDING'))
                        this.$store.dispatch('userGroups', krapending)
                        let krapendingstatus = !!(krapending.length > 0)
                        this.$store.dispatch('setKRAPending', krapendingstatus)
                    }

                    if (response.data.usergroups == null || response.data.usergroups == "") {
                        this.$store.dispatch('userGroups', [])
                    }


                    console.log('Referrer URL: ' + document.referrer)
                    if (!document.referrer) {
                        this.$store.dispatch('firstLogin', true)
                    }
                    if (response.data.norenSessionData.uid == "" || response.data.norenSessionData.uid == null) {
                        this.$store.dispatch('setSessionTimeout', true)
                    }
                } else {
                    if (window.location.pathname == '/pricing' || window.location.pathname == '/') {
                        console.log('MAIN PAGE PRICING HOME')
                    } else {
                        window.location.assign('/ssologin')
                    }
                }

            })
            .catch(error => {
                if (error.response.status == 401) {
                    if (window.location.pathname == '/pricing' || window.location.pathname == '/') {
                        console.log('MAIN PAGE')
                    } else {
                        window.location.assign('/ssologin')
                    }
                }
                console.log(error)
            })
    }

}).$mount('#app')