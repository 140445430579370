import Vue from "vue"
import Router from "vue-router"
import Meta from "vue-meta"

Vue.use(Meta)
Vue.use(Router)





// function loadView(view) {
//     return () =>
//         import ( /* webpackChunkName: "view-[request] " */ `../views/${view}.vue`)
// }

// function loadBase(view) {
//     return () =>
//         import ( /* webpackChunkName: "view-[request] " */ `@/components/${view}.vue`)
// }


const OptionsAlgorithm = () =>
    import ( /* webpackChunkName: "group-foo" */ '@/components/optionsalgo/OptionsAlgorithm.vue')

const OptionsBacktesting = () =>
    import ( /* webpackChunkName: "group-backtesting" */ '@/components/backtesting/OptionsBacktesting.vue')

const OptionSimulator = () =>
    import ( /* webpackChunkName: "group-optionsimulator" */ '@/components/optionsimulator/OptionSimulator.vue')

const SavedSimulationRecords = () =>
    import ( /* webpackChunkName: "group-optionsimulator" */ '@/components/optionsimulator/SavedSimulationRecords.vue')

const OptionsDashboard = () =>
    import ( /* webpackChunkName: "group-optionsdashboard" */ '@/components/dashboard/OptionsDashboard.vue')

const IVCharts = () =>
    import ( /* webpackChunkName: "group-ivcharts" */ '@/components/iv/IVCharts.vue')

const VolSurfaceChart = () =>
    import ( /* webpackChunkName: "group-volsurfacecharts" */ '@/components/iv/VolatilitySurface.vue')


const StrategyCharts = () =>
    import ( /* webpackChunkName: "group-StrategyCharts" */ '@/components/strategycharts/StrategyCharts.vue')

const HistoricalCharts = () =>
    import ( /* webpackChunkName: "group-HistoricalCharts" */ '@/components/strategycharts/HistoricalCharts.vue')

const StrategyBuilder = () =>
    import ( /* webpackChunkName: "group-StrategyBuilder" */ '@/components/strategybuilder/StrategyBuilder.vue')

const Trade = () =>
    import ( /* webpackChunkName: "group-StrategyBuilder" */ '@/components/strategybuilder/Trade.vue')

const Portfolio = () =>
    import ( /* webpackChunkName: "group-StrategyBuilder" */ '@/components/strategybuilder/Portfolio.vue')

const Archive = () =>
    import ( /* webpackChunkName: "group-StrategyBuilder" */ '@/components/strategybuilder/Archive.vue')

const Analyze = () =>
    import ( /* webpackChunkName: "group-StrategyBuilder" */ '@/components/strategybuilder/Analyze.vue')

const PlaceOrder = () =>
    import ( /* webpackChunkName: "group-Trading" */ '@/components/trading/PlaceOrder.vue')

const Positions = () =>
    import ( /* webpackChunkName: "group-Trading" */ '@/components/trading/Positions.vue')

const OrderBook = () =>
    import ( /* webpackChunkName: "group-Trading" */ '@/components/trading/OrderBook.vue')

const Watchlist = () =>
    import ( /* webpackChunkName: "group-Trading" */ '@/components/trading/Watchlist.vue')

const TradeBook = () =>
    import ( /* webpackChunkName: "group-Trading" */ '@/components/trading/TradeBook.vue')

const VolSkew = () =>
    import ( /* webpackChunkName: "group-volskew" */ '@/components/volskew/VolSkew.vue')


const HistoricalResultsTimings = () =>
    import ( /* webpackChunkName: "group-results" */ '@/components/results/HistoricalResultsTimings.vue')

const Calendar = () =>
    import ( /* webpackChunkName: "group-results" */ '@/components/results/Calendar.vue')

const OptionsActivity = () =>
    import ( /* webpackChunkName: "group-openinterest" */ '@/components/dashboard/OptionsActivity.vue')

const OpenInterest = () =>
    import ( /* webpackChunkName: "group-openinterest" */ '@/components/openinterest/OpenInterest.vue')

const OptionChain = () =>
    import ( /* webpackChunkName: "group-optionchain" */ '@/components/openinterest/OptionChain.vue')

const TOI = () =>
    import ( /* webpackChunkName: "group-toi" */ '@/components/openinterest/TOI.vue')

const HTOI = () =>
    import ( /* webpackChunkName: "group-htoi" */ '@/components/openinterest/HistoricalTOI.vue')

const MultiTOI = () =>
    import ( /* webpackChunkName: "group-mtoi" */ '@/components/openinterest/MultiTOI.vue')

const PutCallOI = () =>
    import ( /* webpackChunkName: "group-putcalloi" */ '@/components/openinterest/PutCallOI.vue')

const OptionsBuildup = () =>
    import ( /* webpackChunkName: "group-OptionsBuildup" */ '@/components/openinterest/OptionsBuildup.vue')

const OICharts = () =>
    import ( /* webpackChunkName: "group-oicharts" */ '@/components/openinterest/OICharts.vue')

const OICrossover = () =>
    import ( /* webpackChunkName: "group-oicharts" */ '@/components/openinterest/OICrossover.vue')

const Trividh = () =>
    import ( /* webpackChunkName: "group-oicharts" */ '@/components/openinterest/Trividh.vue')

const BreakevenChain = () =>
    import ( /* webpackChunkName: "group-oicharts" */ '@/components/openinterest/BreakevenChain.vue')

const PCR = () =>
    import ( /* webpackChunkName: "group-pcr" */ '@/components/futures/PCR.vue')

const Straddles = () =>
    import ( /* webpackChunkName: "group-straddles" */ '@/components/openinterest/Straddles.vue')

const UnusualOptionsActivity = () =>
    import ( /* webpackChunkName: "group-UnusualOptionsActivity" */ '@/components/openinterest/UnusualOptionsActivity.vue')

const FIIDII = () =>
    import ( /* webpackChunkName: "group-fiidii" */ '@/components/fiidata/FIIData.vue')

const FIIDIIDerivatives = () =>
    import ( /* webpackChunkName: "group-fiidii" */ '@/components/fiidata/FIIDerivatives.vue')

const Home = () =>
    import ( /* webpackChunkName: "group-Main" */ '@/views/Home.vue')

const Heatmap = () =>
    import ( /* webpackChunkName: "group-heatmap" */ '@/components/heatmap/HeatMap.vue')

const Futures = () =>
    import ( /* webpackChunkName: "group-Futures" */ '@/components/futures/Futures.vue')

const FuturesDashboard = () =>
    import ( /* webpackChunkName: "group-Futures" */ '@/components/futures/FuturesDashboard.vue')

const FuturesIntra = () =>
    import ( /* webpackChunkName: "group-Futures" */ '@/components/futures/Intra.vue')

const FuturesBuildup = () =>
    import ( /* webpackChunkName: "group-Futures" */ '@/components/futures/Buildup.vue')

const MovingAverages = () =>
    import ( /* webpackChunkName: "group-Futures" */ '@/components/futures/MovingAverages.vue')

const MWPL = () =>
    import ( /* webpackChunkName: "group-Futures" */ '@/components/futures/MWPL.vue')

const Rollover = () =>
    import ( /* webpackChunkName: "group-Futures" */ '@/components/futures/RolloverAnalysis.vue')

const Account = () =>
    import ( /* webpackChunkName: "group-User" */ '@/components/User/Account.vue')

const Margin = () =>
    import ( /* webpackChunkName: "group-User" */ '@/components/User/Margin.vue')

const PTRegistration = () =>
    import ( /* webpackChunkName: "group-User" */ '@/components/User/PTRegistration.vue')

const Pricing = () =>
    import ( /* webpackChunkName: "group-User" */ '@/components/User/Pricing.vue')

const Volskewchart = () =>
    import ( /* webpackChunkName: "group-Futures" */ '@/components/volskew/VolSkewCharts.vue')

const PairTradingScreener = () =>
    import ( /* webpackChunkName: "group-PairTrading" */ '@/components/pairtrading/PairTradingScreener.vue')

const PairTradingBuilder = () =>
    import ( /* webpackChunkName: "group-PairTrading" */ '@/components/pairtrading/PairBuilder.vue')

const PairTradingPortfolio = () =>
    import ( /* webpackChunkName: "group-PairTrading" */ '@/components/pairtrading/PairPortfolio.vue')

const PairTradingArchive = () =>
    import ( /* webpackChunkName: "group-PairTrading" */ '@/components/pairtrading/PairArchive.vue')
const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            title: 'Home Page - Opstra',
            metaTags: [{
                    name: 'description',
                    content: 'Opstra Options Analytics'
                },
                {
                    property: 'og:description',
                    content: 'The home page of our Opstra app.'
                }
            ]
        }
    },
    {
        path: '/strategybuilder',
        name: 'StrategyBuilder',
        component: StrategyBuilder,
    },
    {
        path: '/placeorder',
        name: 'Place Order',
        component: PlaceOrder,
    },


    {
        path: '/portfolio',
        name: 'PortFolio',
        component: Portfolio,
    },
    {
        path: '/trade/:id',
        name: 'Trade',
        component: Trade,
    },
    {
        path: '/analyze',
        name: 'Analyze',
        component: Analyze,
    },
    {
        path: '/archive',
        name: 'Archive',
        component: Archive,
    },
    {
        path: '/orders',
        name: 'Orders',
        component: OrderBook,
    },
    {
        path: '/positions',
        name: 'Positions',
        component: Positions,
    },
    {
        path: '/trades',
        name: 'TradeBook',
        component: TradeBook,
    },
    {
        path: '/watchlist',
        name: 'Watchlist',
        component: Watchlist,
    },

    {
        path: '/backtesting',
        name: 'optionbacktesting',
        component: OptionsBacktesting,
    },


    {
        path: '/options',
        name: 'options',
        component: OptionsDashboard,
    },
    {
        path: '/optionsactivity',
        name: 'optionsactivity',
        component: OptionsActivity,
    },
    {
        path: '/heatmap',
        name: 'heatmap',
        component: Heatmap,
    },
    {
        path: '/optionsalgo',
        name: 'optionsalgo',
        component: OptionsAlgorithm,
    },
    {
        path: '/results-calendar',
        name: 'resultscalendar',
        component: Calendar,

    },
    {
        path: '/historical-results-timings',
        name: 'historicalresultstiming',
        component: HistoricalResultsTimings,

    },
    {
        path: '/futures',
        name: 'Futures',
        component: Futures,

    },
    {
        path: '/volskew/charts/:id',
        name: 'Volskewchart',
        component: Volskewchart,
    },
    {
        path: '/futures/dashboard',
        name: 'Futuresdashboard',
        component: FuturesDashboard,
    },
    {
        path: '/futures/intra',
        name: 'FuturesIntra',
        component: FuturesIntra,
    },
    {
        path: '/futures/buildup',
        name: 'FuturesBuildup',
        component: FuturesBuildup,
    },
    {
        path: '/futures/movingaverages',
        name: 'MovingAverages',
        component: MovingAverages,
    },
    {
        path: '/mwpl',
        name: 'MWPL',
        component: MWPL,
    },
    {
        path: '/rollover',
        name: 'RolloverAnalysis',
        component: Rollover,
    },

    {
        path: '/options-simulator',
        name: 'OptionSimulator',
        component: OptionSimulator,
    },
    {
        path: '/options-simulator/:id',
        name: 'OptionSimulator',
        component: OptionSimulator,
    },
    {
        path: '/simulations',
        name: 'Option Simulations',
        component: SavedSimulationRecords,
    },
    {
        path: '/ivchart',
        name: 'IVCharts',
        component: IVCharts,
    },
    {
        path: '/ivchart/:id',
        name: 'IVChart',
        component: IVCharts,
    },

    {
        path: '/volsurface',
        name: 'VolatilitySurface',
        component: VolSurfaceChart,
    },
    {
        path: '/strategy-charts',
        name: 'StrategyCharts',
        component: StrategyCharts,
    },
    {
        path: '/historical-charts',
        name: 'HistoricalCharts',
        component: HistoricalCharts,
    },

    {
        path: '/volskew',
        name: 'VolSkew',
        component: VolSkew,
    },
    {
        path: '/openinterest',
        name: 'OpenInterest',
        component: OpenInterest,
    },
    {
        path: '/optionchain',
        name: 'OptionChain',
        component: OptionChain,
    },
    {
        path: '/toi',
        name: 'TOI',
        component: TOI,
    },
    {
        path: '/htoi',
        name: 'HTOI',
        component: HTOI,
    },
    {
        path: '/multitoi',
        name: 'MultiTOI',
        component: MultiTOI,
    },
    {
        path: '/putcalloi',
        name: 'PutCallOI',
        component: PutCallOI,
    },
    {
        path: '/oicharts',
        name: 'OICharts',
        component: OICharts,
    },
    {
        path: '/optionsbuildup',
        name: 'OptionsBuildup',
        component: OptionsBuildup,
    },

    {
        path: '/pcr',
        name: 'PCR',
        component: PCR,
    },

    {
        path: '/straddles',
        name: 'Straddles',
        component: Straddles,
    },
    {
        path: '/uoa',
        name: 'UnusualOptionsActivity',
        component: UnusualOptionsActivity,
    },
    {
        path: '/oicrossover',
        name: 'OICrossover',
        component: OICrossover,
    },
    {
        path: '/trividh',
        name: 'Trividh',
        component: Trividh,
    },
    {
        path: '/breakevenchain',
        name: 'BreakevenChain',
        component: BreakevenChain,
    },
    {
        path: '/fiidii',
        name: 'FIIDII',
        component: FIIDII,
    },

    {
        path: '/fiidii/derivatives',
        name: 'FIIDIIDerivatives',
        component: FIIDIIDerivatives,
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
    },

    {
        path: '/margin',
        name: 'Margin',
        component: Margin,
    },
    {
        path: '/ptregistration',
        name: 'PTRegistration',
        component: PTRegistration,
    },
    {
        path: '/pricing',
        name: 'Pricing',
        component: Pricing,
    },
    {
        path: '/pairtrading/screener',
        name: 'PairTradingScreener',
        component: PairTradingScreener,
    },
    {
        path: '/pairtrading/builder/:tickers',
        name: 'PairTradingBuilder',
        component: PairTradingBuilder,
    },
    {
        path: '/pairtrading/portfolio',
        name: 'PairTradingPortfolio',
        component: PairTradingPortfolio,
    },
    {
        path: '/pairtrading/archive',
        name: 'PairTradingArchive',
        component: PairTradingArchive,
    },


]

export default new Router({
    mode: "history",
    routes

})