import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(dayjs);
import dayjs from "dayjs";
//import axios from "axios"
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat"
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.guess();
Vue.use(Vuex);


Vue.filter('currencyFormat', (value) => {
    // console.log(value)
    if (!value) return 0.0;

    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)
})

Vue.filter('currency', (value) => {
    if (!value) return 0.0;

    return new Intl.NumberFormat().format(value)
})

Vue.filter("splitDateTime", (value) => {
    if (!value) return null;
    var datetime = value.split(' ')[1] + ' ' + value.split(' ')[0]
    return datetime
});
Vue.filter("DTEFormat", (value) => {
    if (!value) return null;
    value = value.slice(0, 3) + value.slice(3).toLowerCase();
    return dayjs(value, 'DDMMMYYYY')
});

Vue.filter("datestringFormat", (value) => {
    if (!value) return null;
    return dayjs(parseInt(value), 'X').utcOffset(330).tz("Asia/Kolkata").format("DD-MMM-YYYY HH:mm");
});

Vue.filter("datestringWithTime", (value) => {
    if (!value) return null;
    return dayjs(value, 'HH:mm:ss DD-MM-YYYY').tz("Asia/Kolkata").format("DD-MMM-YYYY HH:mm");
});
Vue.filter("datestringWithMonthName", (value) => {
    if (!value) return null;
    return dayjs(value, 'DD-MM-YYYY').tz("Asia/Kolkata").format("DD-MMM-YYYY");
});
Vue.filter("datestring", (value) => {
    if (!value) return null;
    value = value.slice(0, 3) + value.slice(3).toLowerCase();
    return dayjs(value, 'DDMMMYYYY').tz("Asia/Kolkata").format("DD-MM-YYYY");
});

Vue.filter("truncate", function(datep) {
    if (!datep) return null;
    datep = datep.slice(0, 3) + datep.slice(3).toLowerCase();
    return dayjs(datep, 'DDMMMYYYY').tz("Asia/Kolkata").format("YYYY-MM-DD");
});

export default new Vuex.Store({
    state: {
        positions: [],
        expiries: [],
        strikes: [],
        chartdata: [],
        optiontypes: ["CE", "PE"],
        firstLogin: null,
        user: null,
        username: null,
        usergroups: [],
        krapending: false,
        currentUser: null,
        loading: false,
        error: null,
        orderExecutionStatus: false,
        orderStatusText: {},
        sessiontimeout: false,
        isDialogOpen: false,
        placeorders: [
            // { 'ticker': 'USDINR 27APR22 FUTURES', 'scripid': 1170, 'orderprice': 75.6, 'orderqty': 100, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null },
            // { 'ticker': 'CRUDEOIL 19APR22 FUTURES', 'scripid': 234196, 'orderprice': 8000, 'orderqty': 100, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null },
            // { 'ticker': 'RELIANCE 28APR2022 FUTURES', 'scripid': 66602, 'orderprice': 2430.20, 'orderqty': 250, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null },
            // { 'ticker': 'RELIANCE 31MAR2022 2400CE', 'scripid': 139235, 'orderprice': 47, 'orderqty': 250, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null },
            //{ 'ticker': 'NIFTY 26MAY2022 16000CE', 'scripid': 55515, 'tsym': 'NIFTY26MAY22C16000', 'orderprice': 0, 'orderqty': 100, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null },
            // { 'ticker': 'NIFTY 31MAR2022 16000CE', 'scripid': 51371, 'orderprice': 0, 'orderqty': 100, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null },
            // //{ 'ticker': 'NIFTY 24FEB2022 17600PE', 'scripid': 52440, 'orderprice': 248.20, 'orderqty': 100, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null },
            //{ 'ticker': 'NIFTY 26MAY2022 FUTURES', 'scripid': 43661, 'tsym': 'NIFTY26MAY22F', 'orderprice': 16650, 'orderqty': 100, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null },
            // { 'ticker': 'NIFTY 31MAR2022 17000CE', 'scripid': 51410, 'orderprice': 248.20, 'orderqty': 100, 'ordertype': 'LMT', 'markettype': 'M', 'transactiontype': 'B', 'data': {}, 'status': null }
        ],
        // susertoken: '549b34ef41d6195a772559b7ed4cb97c03b5f41893a061ce1f60a6a547c92617',
        // credentials: {
        //     uid: "TESTINV4",
        //     actid: "TESTINV4",
        //     susertoken: '549b34ef41d6195a772559b7ed4cb97c03b5f41893a061ce1f60a6a547c92617'
        // },
        credentials: {},
        analyzeTrade: [],
        papertrading: {},
        optionsalgo: {
            chartdata: [],
            profit: [],
            spotprice: [],
            maxprofit: "",
            maxloss: "",
            params: [],
            price1: "",
            price2: "",
            price3: "",
            SD: null,
            upper: null,
            lower: null,
        },
    },

    mutations: {
        ADD_POSITION: (state, position) => {
            state.positions.push(position);
        },
        ADD_POSITIONS: (state, positions) => {
            state.positions = positions;
        },
        EMPTY_POSITION: (state) => {
            state.positions = [];
        },
        ADD_CHARTDATA: (state, chartdata) => {
            state.chartdata = chartdata;
        },
        EMPTY_CHARTDATA: (state) => {
            state.chartdata = [];
        },
        ADD_EXPIRIES: (state, expiries) => {
            state.expiries.push(expiries);
        },
        ADD_STRIKES: (state, strikes) => {
            state.strikes.push(strikes);
        },
        EDIT_POSITION: (state, newposition) => {
            /* console.log(state.positions.some(position=> position.id === newposition.id)) */
            const record = state.positions.find(
                (element) => element.id == newposition.id
            );
            if (record) {
                record.expiry = newposition.expiry;
                record.strike = newposition.strike;
                record.optiontype = newposition.optiontype;
                record.buysell = newposition.buysell;
                record.lotqty = newposition.lotqty;
                record.optionprice = newposition.optionprice;
                record.legiv = newposition.legiv;
                //console.log(state.positions)
            }
        },
        DELETE_POSITION: (state, index) => {
            //console.log('Index on Store: ', index)
            state.positions.splice(index, 1);
            //console.log(state.positions)
            /* const record = state.positions.find(element=> element.id==positionid)
                      if (record){
                        state.positions.splice(state.positions.indexOf(record),1)
                        //Vue.delete(state.positions, state.positions.indexOf(record))
                      } */
        },

        setSusertoken(state, payload) {
            state.susertoken = payload
        },


        setPlaceOrders(state, payload) {
            state.placeorders = payload
        },
        setAnalyzeTrade(state, payload) {
            state.analyzeTrade = payload
        },

        setUser(state, payload) {
            state.user = payload;
        },

        setUserGroups(state, payload) {
            console.log(payload)
            state.usergroups = payload;
        },
        setKRAPending(state, payload) {
            console.log(payload)
            state.krapending = payload;
        },
        setFirstLogin(state, payload) {
            state.firstLogin = payload;
        },
        setUsername(state, payload) {
            state.username = payload;
        },

        setUserSession(state, payload) {
            state.credentials = payload
        },
        setUserPaperTrading(state, payload) {
            state.papertrading = payload
        },
        setcurrentUser(state, payload) {
            state.currentUser = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setError(state, payload) {
            state.error = payload;
        },
        clearError(state) {
            state.error = null;
        },
        setSessionTimeout(state, payload) {
            state.sessiontimeout = payload
        },
        setOrderExecutionStatus(state, payload) {
            state.orderExecutionStatus = payload
        },
        setOrderStatusText(state, payload) {
            state.orderStatusText = payload
        },

        addoptionsalgochartdata(state, payload) {
            state.optionsalgo.chartdata = payload;
        },
        addspotprice(state, payload) {
            state.optionsalgo.spotprice = payload;
        },
        addparams(state, payload) {
            state.optionsalgo.params = payload;
        },
        addmaxprofit(state, payload) {
            state.optionsalgo.maxprofit = payload;
        },
        addmaxloss(state, payload) {
            state.optionsalgo.maxloss = payload;
        },
        addprice1(state, payload) {
            state.optionsalgo.price1 = payload;
        },
        addprice2(state, payload) {
            state.optionsalgo.price2 = payload;
        },
        addprice3(state, payload) {
            state.optionsalgo.price3 = payload;
        },
        addupper(state, payload) {
            state.optionsalgo.upper = payload;
        },
        addlower(state, payload) {
            state.optionsalgo.lower = payload;
        },
        addprofit(state, payload) {
            state.optionsalgo.profit = payload;
        },
        addSD(state, payload) {
            state.optionsalgo.SD = payload;
        },
        openDialog(state) {
            state.isDialogOpen = true;
        },
        closeDialog(state) {
            state.isDialogOpen = false;
        }
    },

    actions: {
        setPositions({ commit }, payload) {
            commit("ADD_POSITIONS", payload);
        },

        editPositions(context, payload) {
            context.commit("EDIT_POSITION", payload);
        },

        addPos(context, payload) {
            context.commit("ADD_POSITION", payload);
        },

        emptyPosition(context, payload) {
            context.commit("EMPTY_POSITION", payload);
        },

        emptyChartData(context, payload) {
            context.commit("EMPTY_CHARTDATA", payload);
        },

        addChartData(context, payload) {
            context.commit("ADD_CHARTDATA", payload);
        },

        deletePosition(context, payload) {
            context.commit("DELETE_POSITION", payload);
        },

        clearError({ commit }) {
            commit("clearError");
        },
        setSessionTimeout({ commit }, payload) {
            commit('setSessionTimeout', payload)
        },

        setOrderExecutionStatus({ commit }, paylod) {
            commit('setOrderExecutionStatus', paylod)
        },

        setOrderStatusText({ commit }, payload) {
            commit('setOrderStatusText', payload)
        },

        autoLogin({ commit }, payload) {
            commit("setUser", { id: payload });
        },

        userGroups({ commit }, payload) {
            console.log(payload)
            commit("setUserGroups", payload)
        },

        setKRAPending({ commit }, payload) {
            commit("setKRAPending", payload)
        },

        firstLogin({ commit }, payload) {
            commit("setFirstLogin", payload);
        },
        Name({ commit }, payload) {
            commit("setUsername", {
                firstname: payload["firstname"],
                lastname: payload["lastname"],
                email: payload["email"],
                role: payload["role"],
            });
        },
        Credentials({ commit }, payload) {
            // console.log(payload)
            commit('setUserSession', {
                uid: payload.uid,
                actid: payload.aid,
                susertoken: payload.sUserToken,
                wsurl: payload.wsurl
            })
        },
        Papertrading({ commit }, payload) {
            // console.log(payload)
            commit('setUserPaperTrading', {
                email: payload.email,
                username: payload.userName,
                broker: payload.broker,
                enabled: payload.enabled
            })
        },
        susertoken({ commit }, payload) {
            commit('setSusertoken', payload)
        },

        setorders({ commit }, payload) {
            commit('setPlaceOrders', payload)
        },

        analyzeTrade({ commit }, payload) {
            commit('setAnalyzeTrade', payload)
        },

        async logout({ commit }, payload) {
            const response = await axios.post(
                "/api/session/", { sub: payload.id }
            );
            if (response.data.loggedout == "true") {
                commit("setUser", null);
                window.location.href =
                    "https://sso.definedge.com/auth/realms/definedge/protocol/openid-connect/logout?redirect_uri=https%3A%2F%2Fwww.definedge.com";
            }
        },

        async payment({ commit }, payload) {
            const response = await axios.post(
                "http://opstra.definedge.com/api/session/", { sub: payload.id }
            );
            if (response.data.loggedout == "true") {
                commit("setUser", null);
                window.location.href =
                    "https://sso.definedge.com/auth/realms/definedge/protocol/openid-connect/logout?redirect_uri=https%3A%2F%2Fdashboard.definedge.com";
            }
        },

        toggleOrderBox({ commit, state }) {
            state.isDialogOpen ? commit('closeDialog') : commit('openDialog');
        }
    },
    getters: {
        positions: (state) => {
            return state.positions;
        },
        chartdata: (state) => {
            return state.chartdata;
        },
        user: (state) => {
            return state.user;
        },
        username: (state) => {
            return state.username;
        },

        prouser: (state) => {
            return state.username.role == "DEFINEDGE_ADV_OPSTRA";
        },
        krapending: (state) => {
            return state.krapending
        },

        krausergroup: (state) => {
            return state.usergroups[0]
        },
        firstLogin: (state) => {
            return state.firstLogin
        },
        currentUser: (state) => {
            return state.currentUser;
        },
        loading: (state) => {
            return state.loading;
        },
        error: (state) => {
            return state.error;
        },
        placeorders: state => {
            return state.placeorders
        },
        analyzeTrade: state => {
            return state.analyzeTrade
        },
        susertoken: state => {
            return state.credentials.susertoken
        },
        credentials: state => {
            return state.credentials
        },
        papertrading: state => {
            return state.papertrading
        },
        orderExecutionStatus: state => {
            return state.orderExecutionStatus
        },

        orderStatusText: state => {
            return state.orderStatusText
        },

        sessionTimeout: state => {
            return state.sessiontimeout
        },

        isDialogOpen: state => {
            return state.isDialogOpen
        }


    }
})