import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "fa",
    },
    theme: {
        dark: false,
        default: "light",
        themes: {
            light: {
                primary: "#0096a9",
                secondary: "#455A64",
                accent: "#0097A7",
                error: "#f44336",
                warning: "#ffeb3b",
                info: "#8E24AA",
                success: "#4caf50",
            },
            dark: {
                primary: "#0096a9",
                secondary: "#455A64",
                accent: "#0097A7",
                error: "#f44336",
                warning: "#ffeb3b",
                info: "#8E24AA",
                success: "#4caf50",
            },
        }
    },
});