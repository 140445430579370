<template>
  <v-dialog v-model="orderBoxDialog" max-width="800px" persistent>
    <v-card>
      <v-card-text>
        <v-layout align-center>
          <v-flex xs11 class="title text-center my-5">
            Order Box
          </v-flex>
          <v-flex class="text-right">
            <v-btn icon color="red" @click="closeOrderBoxDialog">
              <v-icon> fa fa-window-close </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-spacer v-if="!dataadded"></v-spacer>
          <v-autocomplete
            ref="autocompleteRef"
            class="ma-2"
            :items="dataList"
            v-model="selectedItem"
            :search-input.sync="search"
            item-text="symunqname"
            return-object
            label="Search Symbol"
            prepend-inner-icon="mdi-magnify"
            hide-no-data
            :filter="filterItems"
            @change="addData(selectedItem)"
            autofocus
          >
          </v-autocomplete>
          <span class="mt-8 ml-3" v-if="!dataadded">(* Alt + K to get the keyboard shortcuts)</span>
          <v-spacer></v-spacer>
          <v-flex row wrap class="d-flex justify-center align-center mx-1" v-if="dataadded">
            <v-btn-toggle mandatory color="grey lighten-5">
              <v-btn small dark @click="changeTransactionType(item)" :class="[item.transactiontype == 'SELL' ? 'grey lighten-3 black--text' : 'green']"> B </v-btn>
              <v-btn small dark @click="changeTransactionType(item)" :class="[item.transactiontype == 'BUY' ? 'grey lighten-3 black--text' : 'red']"> S </v-btn>
            </v-btn-toggle>
          </v-flex>
        </v-layout>
        <v-flex row wrap class="d-flex justify-center align-center ma-1" v-if="dataadded">
          <span class="font-weight-bold subheading">
            {{ getProductName(selectedItem.tradingSymbol) }}
            {{ getExpiryDate(selectedItem.tradingSymbol) }}
          </span>
          <v-chip small label class="ma-2 font-weight-bold" color="#90A4AE" text-color ="#37474F" v-if="getProductName(selectedItem.tradingSymbol).includes('FUTURES')">
            FUTURES
          </v-chip>
          <v-chip small label class="ma-2 font-weight-bold" color="#CFD8DC" text-color ="#37474F" v-else>
            OPTIONS
          </v-chip>
          <v-chip small label class="font-weight-bold" v-if="selectedItem.exchange === 'NFO'" color="#D1C4E9" text-color ="#6200EA"> 
            {{ selectedItem.exchange }}
          </v-chip>
          <v-chip small label class="font-weight-bold" v-else-if="selectedItem.exchange === 'BFO'" color="#e9e4c4" text-color ="#7a7654"> 
            {{ selectedItem.exchange }}
          </v-chip>
          <v-chip small label class="font-weight-bold" v-else-if="selectedItem.exchange === 'MCX'" color="#FFECB3" text-color ="#FF6F00"> 
            {{ selectedItem.exchange }}
          </v-chip>
          <v-chip small label class="font-weight-bold" v-else color="#B9F6CA" text-color ="#00C853"> 
            {{ selectedItem.exchange }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-btn-toggle mandatory color="grey lighten-5">
            <v-btn small text @click="changeProductType(item)" :class="[item.producttype == 'NORMAL' ? 'white blue--text' : 'grey lighten-3 grey--text']"> NRML </v-btn>
            <v-btn small text @click="changeProductType(item)" :class="[item.producttype == 'INTRADAY' ? 'white blue--text' : 'grey  lighten-3 grey--text text--darken-2']"> INTRADAY </v-btn>
          </v-btn-toggle>
        </v-flex>
        <v-layout v-if="dataadded">
          <v-flex sm12 md3 ma-1 mt-6>
            <v-card flat>
              <v-row class="caption my-3" no-gutters>
                <v-col cols="6" class="grey--text text--darken-2 font-weight-bold text-left"> LTP </v-col>
                <v-col cols="6" class="font-weight-bold"> {{ data.lp || '0.00' }} </v-col>
              </v-row>
              <v-row class="caption my-3" no-gutters>
                <v-col cols="6" class="grey--text text--darken-2 font-weight-bold text-left"> Open </v-col>
                <v-col cols="6" class="font-weight-bold"> {{ data.o || '0.00' }} </v-col>
              </v-row>
              <v-row class="caption my-3" no-gutters>
                <v-col cols="6" class="grey--text text--darken-2 font-weight-bold text-left"> High </v-col>
                <v-col cols="6" class="font-weight-bold"> {{ data.h || '0.00' }} </v-col>
              </v-row>
              <v-row class="caption my-3" no-gutters>
                <v-col cols="6" class="grey--text text--darken-2 font-weight-bold text-left"> Low </v-col>
                <v-col cols="6" class="font-weight-bold"> {{ data.l || '0.00' }} </v-col>
              </v-row>
              <v-row class="caption my-3" no-gutters>
                <v-col cols="6" class="grey--text text--darken-2 font-weight-bold text-left"> Prev. Close </v-col>
                <v-col cols="6" class="font-weight-bold"> {{ data.c || '0.00' }} </v-col>
              </v-row>
            </v-card>
          </v-flex>
          <v-card flat>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-right"> Bid Price </th>
                    <th class="text-left"> Ask Price </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="green--text text-right"> {{ data.bp1 || '0.00' }} </td>
                    <td class="red--text text-left"> {{ data.sp1 || '0.00' }} </td>
                  </tr>
                  <tr>
                    <td class="green--text text-right"> {{ data.bp2 || '0.00' }} </td>
                    <td class="red--text text-left"> {{ data.sp2 || '0.00' }} </td>
                  </tr>
                  <tr>
                    <td class="green--text text-right"> {{ data.bp3 || '0.00' }} </td>
                    <td class="red--text text-left"> {{ data.sp3 || '0.00' }} </td>
                  </tr>
                  <tr>
                    <td class="green--text text-right"> {{ data.bp4 || '0.00' }} </td>
                    <td class="red--text text-left"> {{ data.sp4 || '0.00' }} </td>
                  </tr>
                  <tr>
                    <td class="green--text text-right"> {{ data.bp5 || '0.00' }} </td>
                    <td class="red--text text-left"> {{ data.sp5 || '0.00' }} </td>
                  </tr>
                  <tr>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
          <v-spacer></v-spacer>
          <v-flex sm6 md6 mx-1 mt-1>
            <v-sheet outlined :class="[item.transactiontype == 'BUY' ? 'green' : 'red']" style="border-width: 2px">
              <v-card flat class="text-center" :class="[item.transactiontype == 'BUY' ? 'green lighten-5' : 'red lighten-5']">
                <v-layout column justify-center>
                  <v-row class="my-2">
                    <v-col cols="12">
                      <v-btn-toggle mandatory color="blue darken-5">
                        <v-btn small outlined @click="limitOrder(item)"
                          :class="[item.ordertype == 'LMT' ? 'white blue--text' : 'grey lighten-3 grey--text']"
                        >
                          LIMIT
                        </v-btn>
                        <v-btn small outlined @click="marketOrder(item)"
                          :class="[item.ordertype == 'MKT' ? 'white blue--text' : 'grey lighten-3 grey--text text--darken-2']"
                        > 
                          MARKET
                        </v-btn>
                        <v-btn small outlined @click="stopLossOrder(item)"
                          :class="[item.ordertype == 'SL-LMT' ? 'white blue--text' : 'grey lighten-3 grey--text text--darken-2']"
                        >
                          SL
                        </v-btn>
                        <v-btn small outlined @click="stopLossMarketOrder(item)" v-if="getProductName(selectedItem.tradingSymbol).includes('FUTURES')"
                          :class="[item.ordertype == 'SL-MKT' ? 'white blue--text' : 'grey lighten-3 grey--text text--darken-2']"
                        >
                          SL-M
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row class="my-1" no-gutters>
                    <v-col cols="12" class="text-center black--text" v-if="item.ordertype == 'LMT' || item.ordertype == 'MKT'">
                      <span class="caption">Price</span><br />
                      <v-flex xs3 sm6 class="mx-auto mt-2">
                        <v-text-field
                          dense
                          class="centered-input font-weight-bold"
                          v-model="item.orderprice"
                          :disabled="item.ordertype == 'MKT'"
                        >
                        </v-text-field>
                      </v-flex>
                      <span class="caption">Quantity</span><br />
                      <v-flex xs3 sm8 class="mx-auto">
                        <v-text-field
                          dense
                          append-outer-icon="mdi-plus" 
                          @click:append-outer="increment(item)" 
                          prepend-icon="mdi-minus" 
                          @click:prepend="decrement(item)"
                          class="centered-input font-weight-bold"
                          v-model.number="item.orderqty"
                        >
                        </v-text-field>
                      </v-flex>
                    </v-col>
                    <v-col cols="12" class="text-center black--text" v-else>
                      <v-row no-gutters align-center>
                        <v-col cols="6">
                          <span class="caption" v-if="item.ordertype == 'SL-LMT'">Limit Price</span>
                          <span class="caption" v-if="item.ordertype == 'SL-MKT'">Price</span>
                          <br />
                          <v-flex xs10 class="mx-auto mt-2">
                            <v-text-field
                              dense
                              outlined
                              class="centered-input font-weight-bold"
                              v-model="item.orderprice"
                              :disabled="item.ordertype == 'SL-MKT'"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col cols="6">
                          <span class="caption">Trigger Price</span><br />
                          <v-flex xs10 class="mx-auto mt-2">
                            <v-text-field
                              dense
                              outlined
                              class="centered-input font-weight-bold"
                              v-model="item.triggerprice"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                        <v-col cols="12">
                          <span class="caption">Quantity</span><br />
                          <v-flex xs3 sm8 class="mx-auto">
                            <v-text-field
                              dense
                              append-outer-icon="mdi-plus" 
                              @click:append-outer="increment(item)" 
                              prepend-icon="mdi-minus" 
                              @click:prepend="decrement(item)"
                              class="centered-input font-weight-bold"
                              v-model.number="item.orderqty"
                            >
                            </v-text-field>
                          </v-flex>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-layout>
              </v-card>
            </v-sheet>
          </v-flex>
        </v-layout>  
        <v-divider class="mt-3" v-if="dataadded"></v-divider>  
        <v-flex row wrap class="d-flex justify-end align-end my-2" v-if="dataadded">
          <span class="font-weight-bold ml-4">Margin Required: <span class="grey--text text--darken-1">{{ marginRequired | currencyFormat }}</span></span>
          <v-flex class="text-right">
            <v-btn class="mr-5 pr-5" py-2 large outlined color="primary" @click="addWatchlist(selectedItem)"> 
                Add to Watchlist
            </v-btn>
            <v-btn py-2 large text dark :class="[item.transactiontype == 'BUY' ? 'green' : 'red']" @click="placeOrder(item)"> 
                Place Order
            </v-btn>
          </v-flex>
        </v-flex>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar1" :top="y === 'top'" :right="x === 'right'">
        {{ snackbar1Text }}
        <v-btn color="pink" text @click="snackbar1 = false"> Close </v-btn>
      </v-snackbar>
  </v-dialog>
</template>

<script>
import axios from "axios";
import SharedWebSocket from '../../sharedwebsocket';
import _ from "lodash"

export default {
  metaInfo: {
          // title: 'Opstra Options Analytics- OrderBox',
          // override the parent template and just use the above title only
          titleTemplate: null,
          meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'description', content: 'OrderBox' }
          ],
      },

  data() {
    return {
      snackbar: false,
      snackbarcolor: "green",
      snackbartext: "",
      snackbar1: false,
      y: "top",
      x: "right",
      mode: "vertical",
      timeout: 2000,
      snackbar1Text: "The ticker has been added to the watchlist",
      orderBoxDialog: false,
      overlay:false,
      dataList: [],
      symunqname: [],
      selectedItem: {},
      item: {
        transactiontype: "BUY",
        orderqty: null,
        orderprice: 0,
        lastprice: null,
        tsym: null,
        symbol: null,
        lotsize: null,
        expiry: null,
        marketsegment: null,
        segment: "Options",
        strike: null,
        optionType: null,
        producttype: "NORMAL",
        variety: "REGULAR",
        triggerprice: 0,
        tag: "string",
        markettype: "M",
        ordertype: "MKT", 
        status: null,
        ordernumber: null,
      },
      dataadded: false,
      exchange: "",
      tradingSymbol: "",
      token: null,
      search: "",
      lotqty: 1,
      data: {},
      marginRequired: 0,
      baseURL: process.env.VUE_APP_ROOT_API,
    };
  },

  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
  },
  
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },

  watch: {
    search(newSearch, oldSearch) {
      if (newSearch !== oldSearch) {
        this.showList();
      }
    },
  },

  methods: {

    addData(selectedItem){
      this.dataadded = true;
      this.search = "";
      this.item.transactiontype = "BUY";
      this.item.producttype = "NORMAL";
      this.lotqty = 1;
      this.marginRequired = 0;
      this.item.ordertype = "MKT";
      this.item.orderprice = 0.0;
      this.$refs.autocompleteRef.blur();
      this.exchange = selectedItem.exchange;
      this.token = selectedItem.token;
      this.tradingSymbol = selectedItem.tradingSymbol;
      var susertoken = this.credentials.susertoken;
      var uid = this.credentials.uid;
      var actid = this.credentials.actid;
      var wsurl = this.credentials.wsurl;
      var tickers = selectedItem.exchange + '|' + selectedItem.token;
      let self = this;
        var ws = new SharedWebSocket(wsurl,null,null);
        console.log(ws)
        console.log("Connecting");
        ws.onopen = function (e) {
          console.log(e);
          console.log("Connection Open");
            if (e){
              var json = {
                t: "c",
                uid: uid,
                actid: actid,
                source: "OPST",
                susertoken: susertoken,
              };
              console.log(json);
              ws.send(JSON.stringify(json));
              console.log("Sending message");
            }else{
              console.log('already connected')
              var sjson = { t: "d", k: tickers };
              ws.send(JSON.stringify(sjson));
            }
          }
          var i=0
          ws.onmessage = function (event) { 
            if (i==0){
              var sjson = { t: "d", k: tickers };
              ws.send(JSON.stringify(sjson));
              i++
            }else{
              var exampledata = JSON.parse(event.data);
              if (exampledata["t"] == "dk" || exampledata["t"] == "df" ) {
                  if (exampledata["tk"] == self.token.toString()) {
                    self.data = Object.assign(
                      {},
                      self.data,
                      exampledata
                    );
                    self.item = {
                      transactiontype: self.item.transactiontype,
                      orderqty: self.data.ls * self.lotqty,
                      orderprice: self.item.orderprice,
                      lastprice: Number(self.data.lp),
                      tsym: self.data.ts,
                      symbol: self.getProductName(self.data.ts).split(' ')[0],
                      lotsize: Number(self.data.ls),
                      expiry: self.getExpiryDate(self.data.ts),
                      marketsegment: self.data.e,
                      segment: self.getProductName(self.data.ts).split(' ')[1]=='FUTURES'?'Futures':'Options',
                      strike: self.getProductName(self.data.ts).split(' ')[1],
                      optionType: self.getProductName(self.data.ts).split(' ')[2],
                      producttype: self.item.producttype,
                      variety: "REGULAR",
                      triggerprice: self.item.triggerprice,
                      tag: "string",
                      markettype: "M",
                      ordertype: self.item.ordertype,
                    }; 
                  }
              }
              } 
              if (exampledata["t"] == "om") {
                if (exampledata["norenordno"] == self.item.ordernumber) {
                  self.item.status = exampledata["status"];
                  self.$store.dispatch('setOrderExecutionStatus',true)
                  const color = exampledata['trantype'] == 'S' || 
                                exampledata['status'] == 'REJECTED' || 
                                exampledata['status'] == 'CANCELED' 
                                ? 'red--text' : 'green--text'
                  const statustext =  exampledata['reporttype'] == 'New' ? 'Placed': 
                                      exampledata['status'] == 'Rejected' ? 'Rejected': 
                                      exampledata['reporttype'] == 'Canceled' ? 'Cancelled': 
                                      exampledata['reporttype'] == 'Fill' ? 'Filled': 
                                      exampledata['reporttype'] == 'Replaced' ? 'Modified' : 'Placed'
                  self.$store.dispatch('setOrderStatusText', {'symbol':exampledata['tsym'], 'transactiontype': self.item.transactiontype, 'ordernumber':exampledata["norenordno"], 'status':exampledata["status"], 'color':color, 'reason':exampledata["rejreason"], 'statustext':statustext})
                }
              }   
          };
          ws.onerror = function(event) {
            console.log(event)
          };
          setTimeout(() => { this.getMargin();}, 1000);
    },

    showList: _.debounce(function() {
      var params = {
        exchange: "ALL",
        searchtxt: this.search,
      };
      return axios
      .post('https://opstra.definedgesecurities.com/opstraapi/searchwatchlist', { params })
      .then( (response)=> {
        this.dataList = response.data;
      })
      .catch( (error) =>{
        console.log(error);
      });      
    },200),

    filterItems(item, queryText, itemText){
      const itemTextLc = itemText.toLowerCase()
      return queryText.toLowerCase().split(' ').every(q => itemTextLc.includes(q))
    },

    marketOrder(order) {
      order.ordertype = "MKT";
      order.orderprice = 0.0;
    },

    limitOrder(order) {
      order.ordertype = "LMT";
      order.orderprice = order.lastprice;
    },

    stopLossOrder(order) {
      order.ordertype = "SL-LMT";
      order.orderprice = order.lastprice;
      order.triggerprice= order.lastprice;
    },

    stopLossMarketOrder(order) {
      order.ordertype = "SL-MKT";
      order.orderprice = 0.0;
      order.triggerprice = order.lastprice;
    },

    changeTransactionType(order) {
      order.transactiontype = order.transactiontype == "BUY" ? "SELL" : "BUY";
      this.getMargin();
    },

    changeProductType(order) {
      order.producttype = order.producttype == "NORMAL" ? "INTRADAY" : "NORMAL";
    },

    increment (order) {
      this.lotqty += 1; 
      order.orderqty = order.lotsize * this.lotqty;
      this.getMargin();
    },

    decrement (order) {
      if (this.lotqty > 1) {
        this.lotqty -= 1; 
        order.orderqty = order.lotsize * this.lotqty;
      }
      this.getMargin();
    },

    wait(ms){
      var start = new Date().getTime();
      var end = start;
      while(end < start + ms) {
        end = new Date().getTime();
      }
    },

    async addWatchlist(item){
      var params = {
        watchListName: "OPSTRA WATCHLIST",
        scrips: item.exchange + '|' + item.token,
      };
      this.snackbar1 = true;
      return axios
        .post(this.baseURL+'tradeapi/addscriptowatchlist', params)
        .then( (response)=> {
          console.log(response.data);
        })
        .catch( (error) =>{
          console.log(error);
        });
    },

    async placeOrder(order) {
      var params = {}
      if (order.ordertype == 'LMT' || order.ordertype == 'MKT'){
        params = {
          exchange: order.marketsegment,
          tradingSymbol: this.selectedItem.tradingSymbol,
          orderQuantity: order.orderqty,
          price: order.orderprice,
          productType: order.producttype,
          priceType: order.ordertype,
          orderType: order.transactiontype,
          variety: "REGULAR",
        }
      }else{
        params = {
          exchange: order.marketsegment,
          tradingSymbol: this.selectedItem.tradingSymbol,
          orderQuantity: order.orderqty,
          price: order.orderprice,
          productType: order.producttype,
          priceType: order.ordertype,
          triggerPrice: order.triggerprice,
          orderType: order.transactiontype,
          variety: "REGULAR",
        }
      }
      this.overlay = true;
      return await axios
        .post(this.baseURL+'tradeapi/placeorder', params)
        .then( (response)=> {
          console.log(response)
          this.overlay = false;
          order.status = response.data.status;
          order.ordernumber = response.data.orderId;
          console.log("Status is " + order.status + " Order Number is " + order.ordernumber)
          this.getOrderStatus();
          this.snackbarcolor = order.transactiontype == 'SELL' || response.data.status == 'REJECTED' ? 'red' : 'green';
          this.snackbar = true;
          this.snackbartext = 'Order Placed Order no. '+ response.data.orderId;
          this.orderBoxDialog = false;
          this.closeWebSocketConnection(this.selectedItem);
        }) 
        .catch( (error) => {
          this.overlay = false
          if (error.response.status == 401){
            this.$store.dispatch('setSessionTimeout', true)
          }
          else{
            this.snackbarcolor = 'red';
            this.snackbar = true;
            this.snackbartext = 'Error placing the order, some data is missing';
            console.log(error);
          }
        });
    },

    getOrderStatus() {
      return axios
        .get( this.baseURL+'tradeapi/orderbook')
        .then( (response) => {
            if (response.orderId == this.item.ordernumber) {
              this.item.status = response.status;
            }
          })
        .catch( (error) =>{
          console.log(error);
          if (error.response.status == 401){
            this.$store.dispatch('setSessionTimeout', true)
          }
        });
    },
    
    handleKeyDown(event) {
      if (event.altKey && event.key.toLowerCase() === 'o') {
        this.dataadded = false;
        this.search = "";
        this.selectedItem = {};
        this.orderBoxDialog = true;
      }
      const isInputField = event.target.tagName === "INPUT" || event.target.tagName === "TEXTAREA";
      if (!isInputField) {
        if (event.key === "ArrowUp") {
          this.lotqty += 1; 
          this.item.orderqty = this.item.lotsize * this.lotqty;
          this.getMargin();
        } else if (event.key === "ArrowDown" && this.lotqty > 1) {
          this.lotqty -= 1; 
          this.item.orderqty = this.item.lotsize * this.lotqty;
          this.getMargin();
        } else if (event.key.toUpperCase() === "B") {
            if(this.item.transactiontype != 'BUY'){
              this.changeTransactionType(this.item);
            }
        } else if (event.key.toUpperCase() === "S") {
          if(this.item.transactiontype != 'SELL'){
            this.changeTransactionType(this.item);
          }
        } else if (event.key.toUpperCase() === "N") {
          if(this.item.producttype != 'NORMAL'){
            this.changeProductType(this.item);
          }
        } else if (event.key.toUpperCase() === "I") {
          if(this.item.producttype != 'INTRADAY'){
            this.changeProductType(this.item);
          }
        } else if (event.key.toUpperCase() === "X" && event.altKey) {
            this.positions = [];
            this.item = {};
            this.closeWebSocketConnection(this.selectedItem);
          this.orderBoxDialog = false;
        } else if (event.key.toUpperCase() === "P" && event.altKey && this.orderBoxDialog == true){
          this.placeOrder(this.item);
        }
      }
    },

    getProductName(tradingSymbol) {
      var final = "";
      if (tradingSymbol.match(/[A-Z]+/)=='SENSEX'||tradingSymbol.match(/[A-Z]+/)=='BANKEX'){
        // console.log(tradingSymbol)
          // console.log(this.months.some(mon => tradingSymbol.includes(mon)))
        if (this.months.some(mon => tradingSymbol.includes(mon))){
          final = tradingSymbol.match(/[A-Z]+/)=='SENSEX'? 'SENSEX '+ tradingSymbol.match(/(\d\d\d\d\d)[A-Z][A-Z]$/)[1] +' '+ tradingSymbol.match(/\d\d\d\d\d([A-Z][A-Z])$/)[1]:'BANKEX '+ tradingSymbol.match(/(\d\d\d\d\d)[A-Z][A-Z]$/)[1] +' '+ tradingSymbol.match(/\d\d\d\d\d([A-Z][A-Z])$/)[1]
          // console.log(final)
        }
        else{
          if (tradingSymbol.includes("FUT")){
          final = tradingSymbol.match(/[A-Z]+/)=='SENSEX'? 'SENSEX'+ ' FUTURES':'BANKEX'+ ' FUTURES'
          }else{
            final = tradingSymbol.match(/[A-Z]+/)=='SENSEX'? 'SENSEX '+ tradingSymbol.match(/(\d\d\d\d\d)[A-Z][A-Z]$/)[1] +' '+ tradingSymbol.match(/\d\d\d\d\d([A-Z][A-Z])$/)[1]:'BANKEX '+ tradingSymbol.match(/(\d\d\d\d\d)[A-Z][A-Z]$/)[1] +' '+ tradingSymbol.match(/\d\d\d\d\d([A-Z][A-Z])$/)[1]
            // console.log(final)
          } 
        }
        
        
      }else{
        var splitArray = tradingSymbol.split(tradingSymbol.match(/\d\d[A-Z][A-Z][A-Z]\d\d/)[0]);
        // console.log(splitArray)
        var secondWord = splitArray[1];
        
        if (secondWord.charAt(0) == "P") {
          final = splitArray[0] + " " + secondWord.slice(1, 11) + " PE ";
        } else if (secondWord.charAt(0) == "C") {
          final = splitArray[0] + " " + secondWord.slice(1,11) + " CE ";
        } else if (secondWord.charAt(0) == "F"||secondWord.charAt(5) == "") {
          final = splitArray[0] + " FUTURES ";
        }
      }
      
    
      return final;
    },

    getSENSEXMonth(MON) {
      var months = {'1':'JAN','2':'FEB','3':'MAR','4':'APR','5':'MAY','6':'JUN','7':'JUL','8':'AUG','9':'SEP','10':'OCT','11':'NOV','12':'DEC'};
      return months[MON];
    },

    getExpiryDate(tsym) {
        var expdate = ""
        if (tsym.match(/[A-Z]+/)=='SENSEX'||tsym.match(/[A-Z]+/)=='BANKEX'){
          if (this.months.some(mon => tsym.includes(mon))){
            var expirymon = this.months.filter(mon => tsym.includes(mon))
            // console.log(expirydatemonthly, this.sensexmonthlies)
            var expirydatemonthly = tsym.match(/[A-Z]+/)=='SENSEX'? this.sensexmonthlies.filter(mon => mon.includes(expirymon))[0]:this.bankexmonthlies.filter(mon => mon.includes(expirymon))[0]
            if (tsym.includes("FUT")){
              expdate = expirydatemonthly.slice(0,2)+ ' '+tsym.match(/\d\d([A-Z][A-Z][A-Z])FUT/)[1]+' 20'+ tsym.slice(6,8)
            }else{
              expdate = expirydatemonthly.slice(0,2)+ ' '+tsym.match(/\d\d([A-Z][A-Z][A-Z])\d\d/)[1]+' 20'+ tsym.slice(6,8)
            }
          }else{
            expdate = tsym.slice(9,11)+' ' +this.getSENSEXMonth(tsym.slice(8,9))+' 20'+ tsym.slice(6,8)
          }
          
        
        }else{
          var secondWord = tsym.slice(tsym.indexOf(tsym.match(/\d+/))+2);
          expdate = (
            tsym.match(/\d+/) +
            " " +
            secondWord
              .slice(0, 5)
              .replace(secondWord.slice(3, 5), " 20" + secondWord.slice(3, 5))
          );
        }
      return expdate
        
    },

    getMargin: async function () {
      var positions = []
      var pos = {
        "product": "M",
        "exchange": this.selectedItem.exchange,
        "tradingSymbol": this.selectedItem.tradingSymbol,
        "price": this.item.lastprice,
        "orderQuantity": this.item.orderqty,
        "transactionType": this.item.transactiontype == 'BUY' ? "B" : "S",
        "priceType": "LMT"  
      }
      positions.push(pos)
      var url = this.baseURL+`tradeapi/margin`;
      if (pos) {
        try {
          const response = await axios.post(url,{"basketlists":positions});
          this.marginRequired = parseFloat(response.data.newMarginUsedAfterTrade) > parseFloat(response.data.marginUsed) ? parseFloat(response.data.newMarginUsedAfterTrade - response.data.marginUsed) : parseFloat(response.data.newTotalMarginUsed - response.data.marginUsed) 
        } catch (error) {
          console.log(error);
          if (error.response.status == 401) {
            this.$store.dispatch("setSessionTimeout", true); 
          }
        }
      }
      else {
        this.marginRequired = 0;
      }
    },

    closeWebSocketConnection(selectedItem) {
      this.marginRequired = 0;
      var ws = new SharedWebSocket(this.credentials.wsurl,null,null);
      ws.onopen = function (e) {
      console.log(e);
        console.log('already connected')
        var ujson = { t: "ud", k: selectedItem.exchange + "|" + selectedItem.token };
        ws.send(JSON.stringify(ujson));
        console.log(ujson)
      }
      ws.onerror = function(event) {
        console.log(event)
      };
    },

    closeOrderBoxDialog() {
      if(this.dataadded == true)
      {
        this.closeWebSocketConnection(this.selectedItem);
      }
      this.orderBoxDialog = false;
    },

  },
  

  computed: {    
    susertoken() {
      return this.$store.getters.susertoken;
    },

    credentials(){
      return this.$store.getters.credentials;
    }
  },

};
</script>

<style scoped>
  .centered-input >>> input {
    text-align: center;
    color: black !important;
    border-block-color: black !important;
  }

  .v-btn.my-btn {
    min-width: 20px !important;
    min-height: 40px !important;
  }
</style>