<template>
    <v-dialog width="350px" persistent v-model="editDialog">
        <v-btn flat icon color="grey" slot="activator">
            <v-icon small>fas fa-edit</v-icon>
        </v-btn>
        <loading :active.sync="isLoading" 
        :can-cancel="true" 
        :is-full-page="true"></loading>
        <v-card>
            <v-container>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-card-title primary-title>
                            <div class="title">Edit Position</div>
                        </v-card-title>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
                <v-layout row wrap v-if="editedstrike=='FUTURES'">
                    <v-flex xs12>
                        <v-card-text>
                            <v-flex xs12>
                                <v-autocomplete
                                :items="expiries"
                                v-model="editedexpiry"
                                label="Expiry"
                                single-line
                                autocomplete
                                class="title"
                                color="teal"
                                hint="Select Expiry"
                                persistent-hint
                                @input="getFuturePrice"
                                ></v-autocomplete>
                                <v-flex xs12 > 
                                    <v-radio-group v-model="editedbuysell" row xs12>
                                        <v-radio
                                            label="Buy"
                                            color="green"
                                            value="+"
                                        ></v-radio>
                                        <v-radio
                                            label="Sell"
                                            color="red"
                                            value="-"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-flex>
                                <v-flex xs12 > 
                                   <v-flex mb-1 class="body-2"> Lot Qty:</v-flex> <number-input v-model="editedlotqty" :value="1" :step="1" :min="1" :max="1000" :inputtable="true"  inline center controls></number-input>     
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        name="input-3-3"
                                        v-model="editedoptionprice"
                                        class="input-group--focused title"
                                        single-line
                                        hint="Modify Futures Entry Price"
                                        persistent-hint
                                        required
                                        :rules="numberRule"
                                        ></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        type="number"
                                        name="input-3-3"
                                        v-model="editedexitprice"
                                        class="input-group--focused title"
                                        single-line
                                        hint="Enter Exit Price"
                                        persistent-hint
                                        required
                                        :rules="numberRule"
                                        ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-card-text>
                    </v-flex>
                </v-layout>
                <v-layout row wrap v-else-if="segment=='Options'">
                    <v-flex xs12>
                        <v-card-text>
                            <v-flex xs12>
                                <v-autocomplete
                                :items="expiries"
                                v-model="editedexpiry"
                                label="Expiry"
                                single-line
                                autocomplete
                                class="title"
                                color="teal"
                                hint="Select Expiry"
                                persistent-hint
                                @input="getOptionPrice"
                                ></v-autocomplete>
                                <v-flex xs12>
                                    <v-autocomplete
                                    :items="strikes"
                                    v-model="editedstrike"
                                    label="Option Strike"
                                    single-line
                                    autocomplete
                                    class="title"
                                    color="teal"
                                    hint="Select Option Strike"
                                    @input="getOptionPrice"
                                    persistent-hint
                                    ></v-autocomplete>
                                </v-flex>
                                <v-flex xs12>
                                    <v-autocomplete
                                    :items="optiontypes"
                                    v-model="editedoptiontype"
                                    label="Option Type"
                                    single-line
                                    autocomplete
                                    class="title"
                                    color="teal"
                                    hint="Select Option Type"
                                    persistent-hint
                                    @input="getOptionPrice"
                                    ></v-autocomplete>
                                </v-flex>
                                <v-flex xs12 > 
                                    <v-radio-group v-model="editedbuysell" row xs12>
                                        <v-radio
                                            label="Buy"
                                            color="green"
                                            value="+"
                                        ></v-radio>
                                        <v-radio
                                            label="Sell"
                                            color="red"
                                            value="-"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-flex>
                                <v-flex xs12 > 
                                   <v-flex mb-1 class="body-2"> Lot Qty:</v-flex> <number-input v-model="editedlotqty" :value="1" :step="1" :min="1" :max="1000" :inputtable="true"  inline center controls></number-input>     
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        name="input-3-3"
                                        v-model="editedoptionprice"
                                        class="input-group--focused title"
                                        single-line
                                        hint="Modify Options Entry Price"
                                        persistent-hint
                                        required
                                        :rules="numberRule"
                                        ></v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-text-field
                                        name="input-3-3"
                                        type="number"
                                        v-model="editedexitprice"
                                        class="input-group--focused title"
                                        single-line
                                        hint="Enter Exit Price"
                                        persistent-hint
                                        required
                                        :rules="numberRule"
                                        ></v-text-field>
                                </v-flex>
                            </v-flex>
                        </v-card-text>
                    </v-flex>
                </v-layout>
                 
                <v-divider></v-divider>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-card-actions>
                            <v-btn flat blue-grey--text  @click="editDialog=false">Close</v-btn>
                            <v-btn flat green--text  @click="saveChanges" >Modify</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn flat icon color="error" slot="activator" @click="deletePositions(index)">
                                <v-icon small>fas fa-trash-alt</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
   
}
</script>
<style >
    
</style>